import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { sendNotification, setCardOpenTab } from '../../store/actions/cardActions'
import ContentType1 from './contentType1';
import ContentType2 from './contentType2';
import ContentType3 from './contentType3';

const ref = React.createRef();

class TabType1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null,
			isOpening: false
		}
	}

	componentDidMount() {
		// Send can open notification //
		let { cardID } = this.state

	}
	render() {
		let { cardState, pageData, auth, tabInfo, onChangeTabOpen, currentOpenTab } = this.props
		let { page } = this.state
		let { user: authUser } = auth

		let { cardID, highlighVideo, highlightPreview, isOpening } = this.state
		let { selectedCard } = cardState

		let { openTabID } = cardState;

		let { contents: tabContents } = tabInfo

		return (
			<div className="single-tab type-1">

				<div className={`collapsible ${tabInfo.TabID === openTabID && 'is-open'}`}>
					<div className="collapsible-header"
						onClick={() => {
							if (openTabID === tabInfo.TabID) {
								this.props.setCardOpenTab(null)
							} else {
								this.props.setCardOpenTab(tabInfo.TabID)
								this.props.sendNotification(
									{
										"card": tabInfo.CardID,
										"type": "tab",
										"type_id": tabInfo.TabID,
									}
								)
							}
						}}
					>{tabInfo.tab_title}</div>
					<div className="collapsible-content">
						<div className="content-inner">
							{
								tabInfo.contents && tabInfo.contents.map(contentInfo => {

									if (contentInfo.type === "1") {
										return <ContentType1 key={`tab_content_${contentInfo.TabID}`} contentInfo={contentInfo} />
									} else if (contentInfo.type === "2") {
										return <ContentType2 key={`tab_content_${contentInfo.TabID}`} contentInfo={contentInfo} />
									} else if (contentInfo.type === "3") {
										return <ContentType3 key={`tab_content_${contentInfo.TabID}`} contentInfo={contentInfo} />
									}
								})
							}
						</div>
					</div>
				</div>

				{/* <Collapsible
					onOpening={() => {
						this.setState({ isOpening: true })
					}}
					onClosing={() => {
						this.setState({ isOpening: true })
					}}
					onClose={() => {
						this.setState({ isOpening: false })
						if (openTabID === tabInfo.TabID) {
							this.props.setCardOpenTab(null)
						}
					}}
					onOpen={() => {
						this.setState({ isOpening: false })
						this.props.setCardOpenTab(tabInfo.TabID)

						this.props.sendNotification(
							{
								"card": tabInfo.CardID,
								"type": "tab",
								"type_id": tabInfo.TabID,
							}
						)
					}}
					trigger={tabInfo.tab_title}>
					{(() => {
						return tabInfo.contents ? tabInfo.contents.map(contentInfo => {

							if (contentInfo.type === "1") {
								return <ContentType1 key={`tab_content_${contentInfo.TabID}`} contentInfo={contentInfo} />
							} else if (contentInfo.type === "2") {
								return <ContentType2 key={`tab_content_${contentInfo.TabID}`} contentInfo={contentInfo} />
							} else if (contentInfo.type === "3") {
								return <ContentType3 key={`tab_content_${contentInfo.TabID}`} contentInfo={contentInfo} />
							}
						}) : '';
					})()}
				</Collapsible> */}


			</div>

		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
	setCardOpenTab,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType1))
