import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import bodyClassController from '../store/utils/bodyClassController'
class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			password: '',
			error: {},
			page: 1
		}
	}

	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	submitHandler = event => {
		event.preventDefault()
	}
	componentDidMount() {

	}

	componentWilMount() {
	}

	handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			this.loadBusiness()
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { pageData } = this.props

		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<div>
					<h1>Not found</h1>
				</div>
			</>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	business: state.business,
})
const mapDispatchToProps = ({
	bodyClassController
})
export default connect(mapStateToProps, mapDispatchToProps)(Home)